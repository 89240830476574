import * as React from 'react'
import { cva, type VariantProps } from 'class-variance-authority'

import { cn } from '@/lib/utils'

const badgeVariants = cva(
  'flex items-center justify-center rounded-full text-center',
  {
    variants: {
      variant: {
        default: 'body-sm-regular bg-card-quinary px-3 text-muted-foreground',
        chip: 'bg-card-tertiary px-3 py-2 text-sm font-bold md:text-base lg:text-lg',
      },
    },
    defaultVariants: {
      variant: 'default',
    },
  }
)

export interface BadgeProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof badgeVariants> {}

function Badge({ className, variant, ...props }: BadgeProps) {
  return (
    <div className={cn(badgeVariants({ variant }), className)} {...props} />
  )
}

export { Badge, badgeVariants }
