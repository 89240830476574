export interface ImageOptions {
  format?: 'jpg' | 'png' | 'webp' | 'gif' | 'avif'
  progressive?: boolean
  quality?: number
  width?: number
  height?: number
  fit?: 'pad' | 'fill' | 'scale' | 'crop' | 'thumb'
  focus?:
    | 'center'
    | 'top'
    | 'right'
    | 'left'
    | 'bottom'
    | 'top_right'
    | 'top_left'
    | 'bottom_right'
    | 'bottom_left'
    | 'face'
    | 'faces'
  radius?: number | 'max'
  backgroundColor?: string
}

export const defaultOptions: ImageOptions = {
  fit: 'fill',
  focus: 'faces',
  format: 'webp',
}

export function generateImageUrl(
  baseUrl: string,
  options: Partial<ImageOptions> = {}
): string {
  const fullUrl =
    baseUrl.startsWith('http://') || baseUrl.startsWith('https://')
      ? baseUrl
      : `https://${baseUrl}`

  const url = new URL(fullUrl)

  const mergedOptions = { ...defaultOptions, ...options }

  const paramMap: Record<keyof ImageOptions, string> = {
    format: 'fm',
    progressive: 'fl',
    quality: 'q',
    width: 'w',
    height: 'h',
    fit: 'fit',
    focus: 'f',
    radius: 'r',
    backgroundColor: 'bg',
  }

  Object.entries(mergedOptions).forEach(([key, value]) => {
    if (value !== undefined) {
      url.searchParams.set(
        paramMap[key as keyof ImageOptions],
        value.toString()
      )
    }
  })

  return url.href
}
