type Subcategory = {
  label: string
  value: string
}

type Category = {
  label: string
  value: string
  description: string
  asset: string
  subcategories: Subcategory[]
}

type TaxonomyManager = {
  categories: Category[]
}

// This will be managed by taxonomy manager in Contentful
export const taxonomyManager: TaxonomyManager = {
  categories: [
    {
      label: 'Mental wellness',
      value: 'mental-wellness',
      description:
        'The latest resources and insights to help you cultivate Mental wellness.',
      asset:
        'images.ctfassets.net/8p2vqb3nf1l9/4O8N8I1N4RJElK8TeTtxxb/e505c0db1aba2760f0cdfe2a1b35ec86/thuan-pham-FtUmbqISW4M-unsplash.jpg',
      subcategories: [
        {
          label: 'Grief support',
          value: 'grief-support',
        },
        {
          label: 'LGBTQIA+',
          value: 'lgbtqia+',
        },
        {
          label: 'Mental health conditions',
          value: 'mental-health-conditions',
        },
        {
          label: 'Mindfulness',
          value: 'mindfulness',
        },
        {
          label: 'Suicide prevention',
          value: 'suicide-prevention',
        },
        {
          label: 'Teen support',
          value: 'teen-support',
        },
        {
          label: 'Cancer',
          value: 'cancer',
        },
        {
          label: 'Substance use',
          value: 'substance-use',
        },
      ],
    },
    {
      label: 'Physical health',
      value: 'physical-health',
      description:
        'The latest resources and insights to help you cultivate Physical health.',
      asset:
        'images.ctfassets.net/8p2vqb3nf1l9/3JKZsG8QkAmFHm5Fo5YD9y/4426f899af598239d7d08b322c253329/alyson-mcphee-yWG-ndhxvqY-unsplash.jpg',
      subcategories: [
        {
          label: 'Cancer',
          value: 'cancer',
        },
        {
          label: 'Fitness',
          value: 'fitness',
        },
        {
          label: 'Menopause',
          value: 'menopause',
        },
        {
          label: 'Nutrition',
          value: 'nutrition',
        },
        {
          label: 'Oral health',
          value: 'oral-health',
        },
        {
          label: 'Pregnancy',
          value: 'pregnancy',
        },
        {
          label: 'Substance use',
          value: 'substance-use',
        },
        {
          label: 'Tobacco and vaping use',
          value: 'tobacco-and-vaping-use',
        },
        {
          label: 'Vision health',
          value: 'vision-health',
        },
      ],
    },
    {
      label: 'Financial fitness',
      value: 'financial-fitness',
      description:
        'The latest resources and insights to help you cultivate Financial fitness.',
      asset:
        'images.ctfassets.net/8p2vqb3nf1l9/3BfdZqTrdCbaSqlvcnXd7d/9ba67a20173a771d2ff33a6d0b30205b/mother_and_daughter_spending_time_together_outside.png',
      subcategories: [
        {
          label: 'Budgeting/saving',
          value: 'budgeting-saving',
        },
        {
          label: 'Child care',
          value: 'child-care',
        },
        {
          label: 'Debt reduction',
          value: 'debt-reduction',
        },
        {
          label: 'Elder care',
          value: 'elder-care',
        },
        {
          label: 'Estate planning',
          value: 'estate-planning',
        },
        {
          label: 'Retirement planning',
          value: 'retirement-planning',
        },
        {
          label: 'Tax planning',
          value: 'tax-planning',
        },
      ],
    },
    {
      label: 'Family & community',
      value: 'family-community',
      description:
        'The latest resources and insights to help you cultivate Family & community.',
      asset:
        'images.ctfassets.net/8p2vqb3nf1l9/3R1IScvX7iePKCj88IUqEY/6ec944146f8f63e85da5a2bfbcd67a49/GettyImages-844424958.jpg',
      subcategories: [
        {
          label: 'Emergency travel assistance',
          value: 'emergency-travel-assistance',
        },
        {
          label: 'Family building',
          value: 'family-building',
        },
        {
          label: 'Pet health',
          value: 'pet-health',
        },
        {
          label: 'Veteran support',
          value: 'veteran-support',
        },
        {
          label: 'People with disabilities',
          value: 'people-with-disabilities',
        },
        {
          label: 'Pregnancy',
          value: 'pregnancy',
        },
        {
          label: 'LGBTQIA+',
          value: 'lgbtqia+',
        },
        {
          label: 'Suicide prevention',
          value: 'suicide-prevention',
        },
        {
          label: 'Teen support',
          value: 'teen-support',
        },
        {
          label: 'Child care',
          value: 'child-care',
        },
        {
          label: 'Elder care',
          value: 'elder-care',
        },
      ],
    },
  ],
}
