'use client'

import { useEffect } from 'react'
import { usePathname } from 'next/navigation'

interface dataLayerArgs {
  event?: string
  category?: string
  subCategories?: string[]
  source?: string
}

function DataLayer({
  event = 'virtualPageView',
  category,
  subCategories,
  source,
}: dataLayerArgs = {}) {
  const path = usePathname()
  const pushToDataLayer = (path: string | null) => {
    const defaultArgs = {
      page: path,
      page_location: window?.location.href,
      page_referrer: document?.referrer,
      page_title: document?.title,
    }

    window.dataLayer = window.dataLayer ?? []
    window.dataLayer.push({
      event,
      ...defaultArgs,
      category,
      subCategories,
      source,
    })
  }
  useEffect(() => {
    pushToDataLayer(path)
  }, [path])
  return null
}

export default DataLayer
